.Footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 200px;
    background-color: #faebd7;
    margin-top: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.FooterContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.FooterText {
  width: auto;
  text-align: center;
  color: #00000011;
  font-size: 48px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  line-height: 0.8;
}

.YearText {
  width: inherit;
  font-size: 24px;
}

.LinksWrapper {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  height: auto;
  width: 100%;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 20px;
}

.LinkStyling {
  width: auto;
  color: #AAAAAA;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
}

.DisabledLink {
  color: #CCCCCC;
  text-decoration: underline;
}

.LinkStyling:visited {
  color: #AAAAAA;
}