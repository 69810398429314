
.IconLabeledWrapper {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.IconStyled {
    width: 160px;
    height: auto;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
}

.LabelStyled {
    width: 180px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    font-size: 24px;
    text-align: center;
}


@media only screen and (max-width: 950px) {
    .IconStyled {
        height: 120px;
        width: auto;
    }

    .LabelStyled {
        width: 140px;
        width: auto;
    }
}