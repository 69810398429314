@import '../../css/Animations.css';
@import '../../css/Common.css';

.HomePage {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.HomePageHeadingImage {
  object-position: 35% 30%;
}

.SaveTheDateWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.SaveTheDateText {
  font-size: 72px;
  font-weight: 800;
  text-align: center;
  color: #ff710d;
}

.Date {
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  line-height: 0.8;
}

.DateImage {
  height: 40vh;
  width: auto;
  pointer-events: none;
}

.IntextImage {
  height: 24px;
  width: 24px;
  margin-left: 5px;
  margin-right: 5px;
}

.TimerElementWrapper {
  width: 100%;
  display: flex;
  width: auto;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.AppearLeft {
  animation: slide-appear-left 1s;
}

.AppearRight {
  animation: slide-appear-right 1s;
}

.AppearTop {
  animation: slide-appear-top 1s;
}

.TimerText {
  font-size: 48px;
  margin-bottom: 10px;
}

.ImageMax600px {
  max-width: 600px;
  width: 100%;
  height: auto;
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (max-width: 1200px) {
  .TimerText {
    font-size: 48px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 1000px) {
  .TimerText {
    font-size: 32px;
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 600px) {
  .TimerText {
    font-size: 24px;
    margin-bottom: 10px;
  }
}