@import url('https://fonts.googleapis.com/css2?family=Freehand&family=Playwrite+SK:wght@100..400&family=Playwrite+US+Trad:wght@100..400&display=swap');

.HomePage {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  
.PageContent {
    display: flex;
    width: auto;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-left: 10%;
    margin-right: 10%;
}

.DarkerBackground {
    background-color: blanchedalmond;
}
  
.Section {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
}
  
.SubHeader {
    width: 100%;
    font-size: 48px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 30px;
    padding-left: 5px;
    padding-right: 5px;
}

.SubSubHeader {
    width: 100%;
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 30px;
    padding-left: 5px;
    padding-right: 5px;
}

.Handwrite {
    font-family: "Playwrite US Trad", cursive;
}

.ContentText {
    font-size: 24px;
    text-align: center;
    text-overflow: ellipsis;
}

.KeptInTheMiddle {
    width: 60%;
}

.ColorWhite {
    color: #ffffff;
}

.ColorOrange {
    color: #ff710d;
}

@media only screen and (max-width: 950px) {
    .PageContent {
        margin-left: 5%;
        margin-right: 5%;
    }

    .KeptInTheMiddle {
        width: 80%;
    }
}

@media only screen and (max-width: 500px) {
    .SubHeader {
        font-size: 40px;
    }

    .KeptInTheMiddle {
        width: 90%;
    }
}