.TimerWrapper {
    display: flex;
    flex-direction: row;
    text-overflow: ellipsis;
}

.TimerUnitWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.TimerUnit {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TimerNumber {
    text-indent: 0px;
    line-height: 0.8;
    color: #ff710d;
    font-style: normal;
    font-size: 144px;
}

.TimerColon {
    font-size: 48px;
    margin-left: 10px;
    margin-right: 10px;
}

.TimerText {
    font-size: 48px;
}
  
.TimerUnit {
    font-style: italic;
    font-size: 32px;
}
  
  @media only screen and (max-width: 1200px) {
    .TimerColon {
      font-size: 48px;
    }
  
    .TimerNumber {
      font-size: 112px;
    }
    
    .TimerUnit {
      font-size: 32px;
    }
  }
  @media only screen and (max-width: 1000px) {
    .TimerColon {
      font-size: 32px;
    }
  
    .TimerNumber {
      font-size: 72px;
    }
    
    .TimerUnit {
      font-size: 24px;
    }
  }
  @media only screen and (max-width: 600px) {
    .TimerColon {
      font-size: 24px;
    }
  
    .TimerNumber {
      font-size: 48px;
    }
    
    .TimerUnit {
      font-size: 16px;
    }
  }