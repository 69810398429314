@import '../../css/Common.css';

.ContactPage {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.ContactPageHeadingImage {
    object-position: 60% 30%;
}

.ContactsHelloText {
    margin-top: 10px;
    margin-bottom: 20px;
}

.TextStyled {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 24px
}

.CenterText {
    text-align: center;
}

.ContactRow {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}

.AppearLeft {
    animation: slide-appear-left 1s;
    animation-fill-mode: forwards;
    visibility: hidden;
}