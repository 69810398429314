@import url('../../css/Common.css');

.DateRow {
    display: flex;
    flex-direction: row;
}

.TimeRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.TimeStyling {
    min-width: 72px;
    padding-right: 10px;
}

.DescriptionStyling {
    text-align: start;
}

.DateSection {
    display: flex;
    flex-direction: column;
    min-width: 60px;
    height: 100%;
    align-items: end;
}

.TimesSection {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
}

.VerticalSeparator {
    min-width: 5px;
    max-width: 5px;
    min-height: 100%;
    background-color: black;
    margin-left: 10px;
    margin-right: 10px;
}