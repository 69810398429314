.Wrapper {
    display: flex;
    flex-direction: column;
}

.Label {
    width: 100%;
    text-align: left;
    font-size: large;
}

.Field {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border-width: 0px;
    position: relative;
    background-color: rgba(204, 153, 25, 0.3);
    transition: 0.3s all;
    margin-top: 5px;
    font-size: x-large;
    padding-left: 5px;
    padding-right: 5px;
}

.FieldFailedValidation {
    outline: 2px red solid;
}

.Field[type="date"]::-webkit-calendar-picker-indicator {
    background-position: 100%;
    cursor: pointer;
    height: auto;
    width: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    padding: 10px;
}


