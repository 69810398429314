.StyledButton {
    height: 60px;
    border-radius: 30px;
    border-width: 0px;
    position: relative;
    font-size: x-large;
    padding-left: 40px;
    padding-right: 40px;
}

.ButtonColors {
    color: #ffffff;
    background-color: #ff7f50;
}

.ButtonColors:hover {
    background-color: #f57547;
}

.ButtonColors:active {
    background-color: #ca6540;
}

.ButtonColors:disabled {
    background-color: #baa9a3;
}