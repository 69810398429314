
.SlideAppearTop {
    animation: slide-appear-top 1s;
    animation-fill-mode: forwards;
    visibility: hidden;
}

.SlideAppearBottom {
    animation: slide-appear-bottom 1s;
    animation-fill-mode: forwards;
    visibility: hidden;
}

.SlideAppearLeft {
    animation: slide-appear-left 1s;
    animation-fill-mode: forwards;
    visibility: hidden;
}

.SlideAppearRight {
    animation: slide-appear-right 1s;
    animation-fill-mode: forwards;
    visibility: hidden;
}

@keyframes slide-appear-top {
    from {
        opacity: 0%;
        transform: translateY(-70%);
    }
    to {
        opacity: 100%;
        transform: translateY(0px);
        visibility: visible;
    }
}

@keyframes slide-appear-bottom {
    from {
        opacity: 0%;
        transform: translateY(70%);
    }
    to {
        opacity: 100%;
        transform: translateY(0px);
        visibility: visible;
    }
}

@keyframes slide-appear-left {
    from {
        opacity: 0%;
        transform: translateX(-70%);
    }
    to {
        opacity: 100%;
        transform: translateX(0px);
        visibility: visible;
    }
}

@keyframes slide-appear-right {
    from {
        opacity: 0%;
        transform: translateX(70%);
    }
    to {
        opacity: 100%;
        transform: translateX(0px);
        visibility: visible;
    }
}

@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 100;
    }
}

@keyframes slide-in-left {
    from {
        left: -100%;
    }
    to {
        left: 0%;
    }
}

@keyframes fade-away {
    from {
        opacity: 100;
    }
    to {
        opacity: 0;
    }
}

@keyframes slide-away-left {
    from {
        left: 0%;
    }
    to {
        left: -100%;
    }
}

@keyframes scale-down-appear {
    from {
        opacity: 0;
        transform: scale(1.5, 1.5);
    }
    to {
        opacity: 100;
        transform: scale(1, 1);
    }
}