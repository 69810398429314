@import '../../../css/Animations.css';

.DrawerOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fade-in 500ms;
}

.OverlayVisible {
    transition: fade-in 500ms;
}

.OverlayInvisible {
    transition: fade-away 500ms;
}

.DrawerHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.DrawerHeaderImage {
    width: 120px;
    height: auto;
}

.DrawerHeaderText {
    width: 100%;
    text-align: center;
    color: azure;
    font-size: 24px;
    font-family:Georgia, 'Times New Roman', Times, serif
}

.DrawerContent {
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
    background-color: #ff7f50;
    z-index: 1001;
    animation: slide-in-left 500ms;
}

.DrawerScrollableContent {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    scrollbar-width: none;
}

.DrawerVisible {
    transition: slide-in-left 500ms;
}

.DrawerInvisible {
    transition: slide-away-left 500ms;
}

.DrawerOutside {
    flex-grow: 1;
    height: 100%;
}

.LinkRow {
    width: 100%;
}

.DrawerButton {
    width: 100%;
    height: 80px;
    margin-top: 1px;
    margin-bottom: 1px;
    background-color: #e26f45;
    border-radius: 5px;
    border-width: 0px;
}

.DrawerButtonContent {
    display: flex;
    flex-direction: column;
}

.DrawerButton:disabled {
    background-color: #9b583f;
}

.DrawerButtonText {
    font-size: x-large;
    color: azure;
}

.DrawerButtonSubText {
    font-size: large;
    color: azure;
}

.DrawerButtonTextDisabled {
    font-size: x-large;
    color: #999999;
}