.App {
    min-height: 100%;
    min-width: 100%;
}
@import "@aws-amplify/ui-react/styles.css";

:root {
    --amplify-fonts-default-variable: "Open Sans";
    --amplify-colors-font-primary: #000000;
    --amplify-fonts-default-static: "Open Sans";
    --amplify-colors-border-primary: #75655297;
    --amplify-colors-font-tertiary: #000000;

    --color-primary:  #ff7f50;
    --amplify-colors-primary-10: hsl(from var(--color-primary) h s calc(l + 35));
    --amplify-colors-primary-20: hsl(from var(--color-primary) h s calc(l + 30));
    --amplify-colors-primary-30: hsl(from var(--color-primary) h s calc(l + 25));
    --amplify-colors-primary-40: hsl(from var(--color-primary) h s calc(l + 20));
    --amplify-colors-primary-50: hsl(from var(--color-primary) h s calc(l + 15));
    --amplify-colors-primary-60: hsl(from var(--color-primary) h s calc(l + 10));
    --amplify-colors-primary-70: hsl(from var(--color-primary) h s calc(l + 5));
    --amplify-colors-primary-80: var(--color-primary);
    --amplify-colors-primary-90: hsl(from var(--color-primary) h s calc(l - 5));
    --amplify-colors-primary-100: hsl(from var(--color-primary) h s calc(l - 10));

    --amplify-components-fileuploader-previewer-background-color: #fbf4ec;
    --amplify-components-fileuploader-dropzone-background-color: #efe4d7;

    --amplify-components-fileuploader-file-background-color: #e1ccb5;
    --amplify-components-fileuploader-file-image-background-color: #00000000;
  
    --amplify-radii-small: 5px;
    --amplify-radii-medium: 10px;
    --amplify-radii-large: 15px;
    --amplify-space-small: 1rem;
    --amplify-space-medium: 1.5rem;
    --amplify-space-large: 2rem;
    --amplify-border-widths-small: 1px;
    --amplify-border-widths-medium: 2px;
    --amplify-border-widths-large: 3px;
    --amplify-border-radius-small: 10px;
}

[data-amplify-authenticator] {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}