
.ReplacementPictogramicWrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

.ReplacedWrapper {
    position: relative;
    top: 0;
    left: 0;
    height: auto;
    width: auto;
}

.ReplacementsWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: auto;
    width: 100%;
    align-items: center;
  }

.AbsoluteBegin {
    position: absolute;
    top: 0;
    left: 0;
}

.Pictogram {
    height: 128px;
    width: auto;
    padding: 5px;
}

.Arrow {
    margin-left: 25px;
    margin-right: 25px;
}

.TintOrange {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(359deg) brightness(119%) contrast(119%);
}


@media only screen and (max-width: 750px) {
    .ReplacementPictogramicWrapper {
        padding-left: 10px;
        padding-right: 10px;
    }

    .Arrow {
        margin-left: 10px;
        margin-right: 10px;
    }

    .Pictogram {
        height: 100px;
        width: auto;
        padding: 2px;
    }
}



@media only screen and (max-width: 380px) {
    .Arrow {
        margin-left: 0px;
        margin-right: 0px;
    }
}