@import '../../css/Animations.css';
@import '../../css/Common.css';

.RsvpPage {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.ConfirmationForm {
    width: 100%;
}

.Fotter {
    height: 200px;
}

.Input {
    margin-bottom: 12px;
}

.InputGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}

.AppearLeft {
    animation: slide-appear-left 1s;
    animation-fill-mode: forwards;
    visibility: hidden;
}

.AppearBottom {
    animation: slide-appear-bottom 1s;
    animation-fill-mode: forwards;
    visibility: hidden;
}

.GroupHeader {
    font-size: larger;
    color: #ff710d;
}

.Gap {
    height: 7px;
}

.ConfirmationButton {
    width: 100%;
}

.ThanksWrapper {
    display: flex;
    width: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.ThanksIcon {
    width: 100px;
    height: auto;
    margin: 10px;
}

.ThanksText {
    font-size: x-large;
    text-align: center;
}

.ErrorWrapper {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #f72020b8;
}

.ErrorText {
    color: #ffffff;
    font-size: large;
    padding-left: 10px;
    padding-right: 10px;
    text-overflow: ellipsis;
}