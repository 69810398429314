@import '../../css/Common.css';

.PartyPage {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.PartyPageHeadingImage {
    object-position: 50% 30%;
}

.ParagraphContentSection {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.IconsWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  height: auto;
  width: 100%;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 20px;
}

.SuggestionsComponent {
    max-width: 800px;
}