@import '../../css/Animations.css';
@import '../../css/Common.css';

.WeddingPage {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.WeddingPageHeadingImage {
    object-position: 25% 50%;
}

.MapFrame {
    width: 100%;
    height: 600px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.ParagraphContentSection {
    display: flex;
    flex-direction: column;
    align-items: center;
}