@import '../../css/Common.css';
@import '../../css/Animations.css';

.AccomodationPage {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.MainText {
    margin-left: 20%;
    margin-right: 20%;
    text-align: center;
    color: #ff710d;
}

.Padding5px {
    padding: 5px;
}

.LogoImg {
    height: 72px;
    width: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

.TintBlack {
    filter: brightness(0.0);
}