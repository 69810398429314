/* Overlay.css */
.drawer-floating-button {
    position: fixed;
    height: 60px;
    width: 60px;
    top: 50px;
    left: 50px;
    z-index: 1000;
    padding: 8px;
    border-radius: 50%;
    border-width: 0px;
    background-color: #ff7f50;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.drawer-floating-button:hover {
    background-color: #f9845a;
}

.drawer-floating-button:active {
    background-color: #ca6540;
}

.drawer-floating-button-image {
    height: 100%;
    width: 100%;
    pointer-events: none;
    color: azure;
}