@import '../../css/Common.css';

.ContactPage {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.SharePageHeadingImage {
    object-position: 50% 50%;
}

.ContactsHelloText {
    margin-top: 10px;
    margin-bottom: 20px;
}

.TextStyled {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 24px
}

.CenterText {
    text-align: center;
}

.ContactRow {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}

.ContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}


.PhotoUploaderWrapper {
    width: 100%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.GuestNameInput {
    width: 100%;
    margin-bottom: 12px;
}

.AppearLeft {
    animation: slide-appear-left 1s;
}
  
.AppearRight {
    animation: slide-appear-right 1s;
}