@import '../../css/Animations.css';

.ParagraphWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
}

.ParagraphContentDirection {
    display: flex;
    flex-direction: row;
}

.ParagraphContentReverseDirection {
    display: flex;
    flex-direction: row-reverse;
}

.ParagraphContent {
    display: flex;
    flex-shrink: 2;
    flex-grow: 0;
    flex-basis: auto;
    height: auto;
    margin-left: 15px;
    margin-right: 15px;
    animation: slide-appear-left 1000ms;
}

.ParagraphImageWrapper {
    align-items: center;
    display: flex;
    flex-shrink: 0.8;
    flex-grow: 0;
    flex-basis: auto;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 15px;
    margin-right: 15px;
    animation: slide-appear-right 1000ms;
}

.ParagraphImage {
    max-width: 100%;
}

@media only screen and (max-width: 950px) {
    .ParagraphContentDirection {
        display: flex;
        flex-direction: column;
    }

    .ParagraphContentReverseDirection {
        display: flex;
        flex-direction: column-reverse;
    }

    .ParagraphContent {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .ParagraphImageWrapper {
        margin-top: 15px;
        margin-bottom: 15px;
    }


    .ParagraphImage {
        max-width: 100%;
        margin-left: 0px;
        margin-right: 0px;
    }
  }