@import '../../css/Animations.css';
@import url('https://fonts.googleapis.com/css2?family=Freehand&family=Playwrite+SK:wght@100..400&family=Playwrite+US+Trad:wght@100..400&display=swap');

.HeadingContainer {
    width: 100%;
    height: fit-content;
    overflow: hidden;
}

.HeadingImage {
    width: 100%;
    min-height: 400px;
    max-height: 500px;
    object-fit: cover;
    animation: scale-down-appear 1000ms;
}

.HeadingContent {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
}

.HeadingText {
    width: auto;
    /* max-width: 60%; */
    font-size: 72px;
    text-align: center;
    font-family: "Playwrite US Trad", cursive;
    font-weight: 800;
    color: #000000;
    text-overflow: ellipsis;
    animation: slide-appear-top 1000ms;
}


@media only screen and (max-width: 700px) {
    .HeadingText {
        max-width: 100%;
        font-size: 48px;
    }
}


@media only screen and (max-width: 500px) {
    .HeadingText {
        max-width: 100%;
        font-size: 40px;
    }
}